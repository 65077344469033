import React, { useEffect, useState } from "react"
import "../scss/mockups.scss"
import Seo from "../components/seo/seo"
import Header from "../components/header/header"
import * as THREE from "three"
import $ from "jquery"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Thumbs } from "swiper"
import "swiper/css"
import { FileDrop } from "react-file-drop"
import { withPrefix } from "gatsby"
import { navigate } from "gatsby-link"
import Footer from "../components/footer/footer"

SwiperCore.use([Navigation, Thumbs])

const Mockups = props => {
  const staticContents = props.pageContext.staticContent
  const staticMockupsContentPage = props.pageContext.staticMockupsContentPage
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const SIZE = { x: 1920, y: 1080 }
  const RTG = Math.PI / 180
  const loader = new THREE.TextureLoader()

  const getPlaneGeo = t => {
    return new THREE.PlaneGeometry(t.w, t.h)
  }
  const getPerspectiveCamera = () => {
    var t = new THREE.PerspectiveCamera(75, SIZE.x / SIZE.y, 0.1, 1e3)
    t.position.z = 400
    return t
  }

  const getPerspectiveCamera1 = () => {
    var t = new THREE.PerspectiveCamera(50, 1, 0.1, 1e4)
    t.position.z = 3e3
    t.position.y = -300
    t.rotation.x = -0.1
    return t
  }
  const getCylGeo = t => {
    let r = 220
    let o = -78 * RTG
    let a = 101 * RTG
    return new THREE.CylinderGeometry(r, r, t.h, 50, 5, !0, o, a)
  }

  const firstSlide = {
    canvasId: "canvas1",
    uploadBtnId: "upload1",
    saveBtnId: "save1",
    defaultImgSrc: "/bigmedia/default3.jpeg",
    maskImgSrc: "/bigmedia/mask3.png",
    screenshotBgSrc: "/bigmedia/screenshotBg3.jpeg",
    getCam: getPerspectiveCamera,
    getGeom: getPlaneGeo,
    billboardParams: {
      w: 340,
      h: 145,
      position: { x: -460, y: 136, z: -233 },
      rotation: { x: 0, y: (Math.PI / 180) * 1.5, z: (-Math.PI / 180) * 1.2 },
    },
    maskParams: { w: 401, h: 188, x: -511, y: 150 },
  }

  const fifthSlide = {
    canvasId: "canvas5",
    uploadBtnId: "upload5",
    saveBtnId: "save5",
    defaultImgSrc: "",
    maskImgSrc: "",
    screenshotBgSrc: "/bigmedia/screenshotBg5.png",
    getCam: getPerspectiveCamera,
    getGeom: getPlaneGeo,
    billboardParams: {
      w: 330,
      h: 170,
      position: { x: -290, y: 190, z: -200 },
      rotation: { x: 0, y: 0, z: 0 },
    },
    maskParams: { w: 401, h: 188, x: -511, y: 150 },
  }

  const secondSlide = {
    canvasId: "canvas2",
    uploadBtnId: "upload2",
    saveBtnId: "save2",
    defaultImgSrc: "/bigmedia/default2.jpeg",
    maskImgSrc: "/bigmedia/mask2.png",
    screenshotBgSrc: "/bigmedia/screenshotBg2.jpeg",
    getCam: getPerspectiveCamera,
    getGeom: getPlaneGeo,
    billboardParams: {
      w: 300,
      h: 487,
      position: { x: 364, y: 116, z: -233 },
      rotation: { x: -3 * RTG, y: -13 * RTG, z: -2 * RTG },
    },
    maskParams: { w: SIZE.x, h: SIZE.y, x: 0, y: 0 },
  }

  const thirdSlide = {
    canvasId: "canvas3",
    uploadBtnId: "upload3",
    saveBtnId: "save3",
    defaultImgSrc: "/bigmedia/default1.jpeg",
    maskImgSrc: "/bigmedia/mask1.png",
    screenshotBgSrc: "/bigmedia/screenshotBg1.jpeg",
    getCam: getPerspectiveCamera,
    getGeom: getPlaneGeo,
    billboardParams: {
      w: 535,
      h: 290,
      position: { x: 264, y: 172, z: -233 },
      rotation: { x: 1 * RTG, y: -3.5 * RTG, z: -0.2 * RTG },
    },
    maskParams: { w: SIZE.x, h: SIZE.y, x: 0, y: 0 },
  }

  let r = 220
  let o = -78 * RTG
  let a = 101 * RTG
  const fourthSlide = {
    canvasId: "canvas4",
    uploadBtnId: "upload4",
    saveBtnId: "save4",
    defaultImgSrc: "/bigmedia/default4.jpeg",
    maskImgSrc: "/bigmedia/mask4.png",
    screenshotBgSrc: "/bigmedia/screenshotBg4.jpeg",
    getCam: getPerspectiveCamera1,
    getGeom: getCylGeo,
    billboardParams: {
      w: (a - o) * r,
      h: 2023,
      position: { x: 955, y: -660, z: -533 },
      rotation: { x: -0.1, y: 0, z: 0 },
    },
    maskParams: { w: SIZE.x, h: SIZE.y, x: 0, y: 0 },
  }

  useEffect(() => {
    initSlide(fifthSlide)
    initSlide(firstSlide)
    initSlide(secondSlide)
    initSlide(thirdSlide)
    initSlide(fourthSlide)
  }, [])

  const onDrop = (files, slide) => {
    $("#" + slide.uploadBtnId).trigger("draggedFile", files)
  }

  function loadImageFromInput(t, e) {
    var n = t.files[0]
    if (n && FileReader) {
      var i = new FileReader()
      i.onloadend = function () {
        loader.load(i.result, function (t) {
          var n = t.image
          e(n)
        })
      }
      i.readAsDataURL(n)
    }
  }

  function mergeImages(t, e) {
    var n = document.createElement("canvas")
    n.width = SIZE.x
    n.height = SIZE.y
    var i = n.getContext("2d")
    return i.drawImage(e, 0, 0), i.drawImage(t, 0, 0), n
  }

  function saveCanvasToFile(t) {
    t.toBlob(function (t) {
      saveAs(t, "screenshot.jpeg")
    })
  }

  const saveAs = (t, filename) => {
    const blob = t
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("style", "display:none;")
    document.body.appendChild(a)
    a.href = url
    a.download = filename
    a.click()
  }

  function initSlide(t) {
    function e(t, e, n) {
      renderAll(t, e, c, n, u, h, l)
    }

    var n = t.canvasId,
      i = t.uploadBtnId,
      r = t.saveBtnId,
      o = withPrefix(t.defaultImgSrc),
      a = withPrefix(t.maskImgSrc),
      s = withPrefix(t.screenshotBgSrc),
      c = t.billboardParams,
      l = t.getGeom,
      u = t.maskParams,
      h = t.getCam,
      d = document.getElementById(n),
      p = loader.load(a, function (t) {
        loader.load(o, function (n) {
          e(d, n, t)
        })
      })
    $(document).on("draggedFile", "#" + i, function (event, file) {
      loadImageFromInput({ files: [file] }, function (t) {
        var n = new THREE.Texture(t)
        n.needsUpdate = true
        e(d, n, p)
      })
      $(this)
        .parents(".video-slide")
        .find(".save-screenshot")
        .addClass("visible")
    })
    $(document).on("change", "#" + i, function () {
      loadImageFromInput(this, function (t) {
        var n = new THREE.Texture(t)
        n.needsUpdate = true
        e(d, n, p)
      })
      $(this)
        .parents(".video-slide")
        .find(".save-screenshot")
        .addClass("visible")
    })
    $(document).on("click", "#" + r, function () {
      loader.load(s, function (t) {
        var e = t.image
        n = mergeImages(d, e)
        saveCanvasToFile(n)
      })
    })
  }

  const renderAll = (t, e, n, i, r, o, a) => {
    var s = new THREE.WebGLRenderer({
      canvas: t,
      antialias: !0,
      alpha: !0,
      preserveDrawingBuffer: !0,
    })
    s.setSize(SIZE.x, SIZE.y)
    s.setClearColor(0x000000, 0)
    var c = new THREE.Scene()
    const l = o()
    const u = new THREE.PointLight(16777215)
    u.position.set(100, 100, 250)
    c.add(u)
      ; (function (t) {
        var i = a(n)
        new THREE.CylinderGeometry(220, 220, 2023, 50, 5, !0, -1.4, 2.4)
        var r = n.w / n.h
        var o = e.image.width / e.image.height
        var s = r / o
        if (s > 1) {
          e.repeat.y = 1 / s
          e.offset.y = (1 - 1 / s) / 2
        } else {
          e.repeat.x = s
          e.offset.x = (1 - s) / 2
        }
        var c = new THREE.MeshBasicMaterial({ map: e })
        c.depthTest = !1
        c.depthWrite = !1
        var l = new THREE.Mesh(i, c)
        l.position.x = n.position.x
        l.position.y = n.position.y
        l.position.z = n.position.z
        l.rotation.x = n.rotation.x
        l.rotation.y = n.rotation.y
        l.rotation.z = n.rotation.z
        t.add(l)
      })(c)
    var h = new THREE.Scene()
    var d = new THREE.OrthographicCamera(
      -SIZE.x / 2,
      SIZE.x / 2,
      SIZE.y / 2,
      -SIZE.y / 2,
      -1e3,
      1e3
    )
      ; (function () {
        var t = new THREE.PlaneGeometry(r.w, r.h)
        var e = new THREE.MeshBasicMaterial({ map: i, transparent: !0 })
        var n = new THREE.Mesh(t, e)
        n.position.x = r.x
        n.position.y = r.y
        h.add(d)
        h.add(n)
      })()
    s.autoClear = !1
    s.clear()
    s.render(c, l)
    s.render(h, d)
  }

  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticContents["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContents["<meta> keywords"]}
          descriptionContent={staticContents["<meta> description"]}
          title={staticContents["<title>"]}
        />
        <div className="mockups-pro mt-4">
          <div className="row mb-4">
            <h1
              className="col"
              dangerouslySetInnerHTML={{
                __html: staticMockupsContentPage["Header"],
              }}
            />
          </div>
          <div className="row ">
            <div className="col-12 mb-3">
              <Swiper
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={1}
                navigation={true}
              >
                <SwiperSlide>
                  <div className="main-slider__container">
                    <div className="video-slider__container">
                      <div className="bx-viewport"></div>
                      <div className="bx-wrapper">
                        <div className="bx-viewport">
                          <ul className="bxslider video-slider">
                            <li className="video-slide billboard-video">
                              <canvas
                                className="canvasMask"
                                id={firstSlide.canvasId}
                              ></canvas>
                              <video
                                className="js-slider-video"
                                loop
                                autoPlay
                                muted
                                poster={withPrefix(
                                  "/video/poster-slide-2.jpeg"
                                )}
                              >
                                <source
                                  src={withPrefix("/video/slide-2.mp4")}
                                  type="video/mp4"
                                />
                                <source
                                  src={withPrefix("/video/slide-2.webm")}
                                  type="video/webm"
                                />
                              </video>
                              <span className="bg-pattern"></span>
                              <FileDrop
                                onDrop={files => onDrop(files, firstSlide)}
                              >
                                <div className="upload-btn">
                                  <input
                                    accept="image/*"
                                    className="inputfile"
                                    id={firstSlide.uploadBtnId}
                                    name="file1"
                                    type="file"
                                  />
                                  <label htmlFor={firstSlide.uploadBtnId}>
                                    <span className="upload-ico"></span>
                                  </label>
                                </div>
                              </FileDrop>

                              <a
                                className="save-screenshot"
                                id={firstSlide.saveBtnId}
                              >
                                <span></span>
                                <b>{staticMockupsContentPage["Save text"]}</b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="main-slider__container">
                    <div className="video-slider__container">
                      <div className="bx-viewport"></div>
                      <div className="bx-wrapper">
                        <div className="bx-viewport">
                          <ul className="bxslider video-slider">
                            <li className="video-slide billboard-video">
                              <canvas
                                className="canvasMask"
                                id={fifthSlide.canvasId}
                              ></canvas>
                              <video
                                className="js-slider-video"
                                loop
                                autoPlay
                                muted
                                poster={withPrefix("/video/poster-slide-1.png")}
                              >
                                <source
                                  src={withPrefix("/video/slide-1.mp4")}
                                  type="video/mp4"
                                />
                                <source
                                  src={withPrefix("/video/slide-1.webm")}
                                  type="video/webm"
                                />
                              </video>
                              <span className="bg-pattern"></span>
                              <FileDrop
                                onDrop={files => onDrop(files, fifthSlide)}
                              >
                                <div className="upload-btn digital">
                                  <input
                                    accept="image/*"
                                    className="inputfile"
                                    id={fifthSlide.uploadBtnId}
                                    name="file5"
                                    type="file"
                                  />
                                  <label htmlFor={fifthSlide.uploadBtnId}>
                                    <span className="upload-ico"></span>
                                  </label>
                                </div>
                              </FileDrop>

                              <a
                                className="save-screenshot"
                                id={fifthSlide.saveBtnId}
                              >
                                <span></span>
                                <b>{staticMockupsContentPage["Save text"]}</b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="main-slider__container">
                    <div className="video-slider__container">
                      <div className="bx-viewport"></div>
                      <div className="bx-wrapper">
                        <div className="bx-viewport">
                          <ul className="bxslider video-slider">
                            <li className="video-slide citylight-video">
                              <canvas
                                className="canvasMask"
                                id={secondSlide.canvasId}
                              ></canvas>
                              <video
                                className="js-slider-video"
                                loop
                                autoPlay
                                muted
                                poster={withPrefix(
                                  "/video/poster-slide-3.jpeg"
                                )}
                              >
                                <source
                                  src={withPrefix("/video/slide-3.mp4")}
                                  type="video/mp4"
                                />
                                <source
                                  src={withPrefix("/video/slide-3.webm")}
                                  type="video/webm"
                                />
                              </video>
                              <span className="bg-pattern"></span>
                              <FileDrop
                                onDrop={files => onDrop(files, secondSlide)}
                              >
                                <div className="upload-btn">
                                  <input
                                    accept="image/*"
                                    className="inputfile"
                                    id={secondSlide.uploadBtnId}
                                    name="file2"
                                    type="file"
                                  />
                                  <label htmlFor={secondSlide.uploadBtnId}>
                                    <span className="upload-ico"></span>
                                  </label>
                                </div>
                              </FileDrop>
                              <a
                                className="save-screenshot"
                                href="#"
                                id={secondSlide.saveBtnId}
                              >
                                <span></span>
                                <b>{staticMockupsContentPage["Save text"]}</b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="main-slider__container">
                    <div className="video-slider__container">
                      <div className="bx-viewport"></div>
                      <div className="bx-wrapper">
                        <div className="bx-viewport">
                          <ul className="bxslider video-slider">
                            <li className="video-slide billboard-video2">
                              <canvas
                                className="canvasMask"
                                id={thirdSlide.canvasId}
                              ></canvas>
                              <video
                                className="js-slider-video"
                                loop
                                autoPlay
                                muted
                                poster={withPrefix(
                                  "/video/poster-slide-4.jpeg"
                                )}
                              >
                                <source
                                  src={withPrefix("/video/slide-4.mp4")}
                                  type="video/mp4"
                                />
                                <source
                                  src={withPrefix("/video/slide-4.webm")}
                                  type="video/webm"
                                />
                              </video>
                              <span className="bg-pattern"></span>
                              <FileDrop
                                onDrop={files => onDrop(files, thirdSlide)}
                              >
                                <div className="upload-btn">
                                  <input
                                    accept="image/*"
                                    className="inputfile"
                                    id={thirdSlide.uploadBtnId}
                                    name="file3"
                                    type="file"
                                  />
                                  <label htmlFor={thirdSlide.uploadBtnId}>
                                    <span className="upload-ico"></span>
                                  </label>
                                </div>
                              </FileDrop>
                              <a
                                className="save-screenshot"
                                href="#"
                                id={thirdSlide.saveBtnId}
                              >
                                <span></span>
                                <b>{staticMockupsContentPage["Save text"]}</b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="main-slider__container">
                    <div className="video-slider__container">
                      <div className="bx-viewport"></div>
                      <div className="bx-wrapper">
                        <div className="bx-viewport">
                          <ul className="bxslider video-slider">
                            <li className="video-slide curbstone-video">
                              <canvas
                                className="canvasMask"
                                id={fourthSlide.canvasId}
                              ></canvas>
                              <video
                                className="js-slider-video"
                                loop
                                autoPlay
                                muted
                                poster={withPrefix(
                                  "/video/poster-slide-5.jpeg"
                                )}
                              >
                                <source
                                  src={withPrefix("/video/slide-5.mp4")}
                                  type="video/mp4"
                                />
                                <source
                                  src={withPrefix("/video/slide-5.webm")}
                                  type="video/webm"
                                />
                              </video>
                              <span className="bg-pattern"></span>
                              <FileDrop
                                onDrop={files => onDrop(files, fourthSlide)}
                              >
                                <div className="upload-btn">
                                  <input
                                    accept="image/*"
                                    className="inputfile"
                                    id={fourthSlide.uploadBtnId}
                                    name="file4"
                                    type="file"
                                  />
                                  <label htmlFor={fourthSlide.uploadBtnId}>
                                    <span className="upload-ico"></span>
                                  </label>
                                </div>
                              </FileDrop>
                              <a
                                className="save-screenshot"
                                href="#"
                                id={fourthSlide.saveBtnId}
                              >
                                <span></span>
                                <b>{staticMockupsContentPage["Save text"]}</b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>

              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={40}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={withPrefix("/video/poster-slide-2.jpeg")} />
                  <p className="text-mockups">
                    {staticMockupsContentPage["Supersight image text"]}
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={withPrefix("/video/poster-slide-1.png")} />
                  <p className="text-mockups">
                    {staticMockupsContentPage["Digital plane image text"]}
                  </p>
                </SwiperSlide>

                <SwiperSlide>
                  <img src={withPrefix("/video/poster-slide-3.jpeg")} />
                  <p className="text-mockups">
                    {staticMockupsContentPage["Citylight image text"]}
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={withPrefix("/video/poster-slide-4.jpeg")} />
                  <p className="text-mockups">
                    {staticMockupsContentPage["Billboard image text"]}
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={withPrefix("/video/poster-slide-5.jpeg")} />
                  <p className="text-mockups">
                    {staticMockupsContentPage["Column image text"]}
                  </p>
                </SwiperSlide>
              </Swiper>
            </div>
            <div>
              <div className="mt-5">
                <div className="row">
                  <h1
                    className="col"
                    dangerouslySetInnerHTML={{
                      __html: staticContents["Page Header"],
                    }}
                  />
                </div>
                <div className="row">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: staticMockupsContentPage["Section description"],
                    }}
                    className="col mt-4"
                  ></h3>
                </div>
              </div>
              <div className=" mb-8">
                <button
                  type="button"
                  onClick={() => navigate("/mockups-pro")}
                  className="btn btn-primary btn-lg mt-5 col"
                >
                  {staticMockupsContentPage["Button text"]}
                </button>
                <div className="col-lg-9" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default Mockups
